export default {
    namespaced: true,
    state: {
        LOGIN_PAGE: '/login.html',
        SIMPLE_REGISTRATION: '/simple-registration.html',
        COOKIES_EXPIRES: 15 * 60 * 1000,
        FORGET_PASSWORD_PAGE: '/account-recovery.html',
        LOGIN_REDIRECT_DOMAINS: ['dow.com'],
        DEFAULT_REDIRECT_URL: '/my-account.html',
        URL_BASE:
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : ''),
        LANGUAGE_ARRAY: [
            'pt-br',
            'ja-jp',
            'zh-cn',
            'en-us',
            'ko-kr',
            'de-de',
            'es-es'
        ],
        SP_DATATYPE: 'json',
        GEO_LOC_DEFAULT_COUNTRY: 'US',
        GEO_LOC_DEFAULT_COUNTRYNAME: 'United States',
        HSESSION_DELAYTIME: 3,
        interest_update: '/.dow.bin.emailsignup.json',
        login_servlet: '/.dow.commerce.login.json',
        logout_servlet: '/.dow.commerce.logout.json',
        reauth_servlet: '/.dow.reauth.login.json',
        get_search_token_generation: '/.dow.search.token.servlet.json',
        favorite_servlet: '/.dow.pdp.webproduct.favorite.json',
        favorite_search_servlet: '/.dow.pdp.webproductsearch.favorite.json',
        pdp_search_servlet: '/.dow.pdp.search.servlet.json',
        material_favorite_servlet: '/.dow.pdp.material.favorite.json',
        generated_facets_servlet: '/.dow.generated.facets.json',
        search_token_servlet: '/.dow.search.token.servlet.json',
        country_regions: '/.dow.commerce.dropdown.json',
        cmir_servlet: '/.dow.cmir.json',
        FIND_DIST: {
            select_state: 'State/County/Province', // value displayed in the region dropdown if no region is selected
            name_param: 'name', // should match the name of the hidden name input on find a distributor
            desc_param: 'desc', // should match the name of the hidden desc input on find a distributor
            material_param: 'q10', // should match the name of the hidden material input on find a distributor
            category_param: 'q11', // should match the name of the hidden category input on find a distributor
            country_param: 'q12', // should match the id & name of the hidden country dropdown on find a distributor
            state_param: 'q13', // should match the id & name of the hidden region dropdown on find a distributor
            zip_param: 'q14', // should match the name of the postal code input on find a distributor
            refine_by: 'market.Title',
            template_id: '#dcc-dropdown-findADistributor', // apply a customed template for distributor.
            distributorShortUrl: '/distributorfinder.html'
        },
        COUNTRY_REGION: {
            country_param: 'country', // query parameter the AEM servlet needs for country
            country_value: 'isocode', // value in dropdown should match JSON value specified
            state_param: 'state', // query parameter the AEM servlet needs for country
            state_value: 'isocode' // value in dropdown should match JSON value specified
        },
        pdp_bundle_pricing: '/dccstorefront/p/productFamilyPricing',
        get_sds: '/.dow.sds.doc.json',
        get_tds: '/.dow.tds.json',
        get_rdsdoc: '/.dow.rds.doc.json',
        get_fdsdoc: '/.dow.fds.doc.json',
        trade_products: '/.dow.trade.products.servlet',
        get_product_categories: '/.dow.product.categories.servlet.json',
        sample_cart_servlet: '/.dow.sample.cart.json',
        sample_cart_get_servlet: '/.dow.sample.cart.get.json',
        sample_cart_count_servlet: '/.dow.sample.cart.count.json',
        sample_cart_remove_servlet: '/.dow.sample.cart.remove.json',
        sample_cart_delete_cart_servlet: '/.dow.sample.cart.delete.cart.json',
        contact_us: '/.dow.contactUs.servlet.json',
        cookie_servlet: '/.dow.cookie.utils.servlet.json',
        product_attributes_servlet: '/.dow.product.attributes.servlet.json',
        //My-Account servlets
        get_orders: '/.dow.order.search.servlet.json',
        get_order: '/.dow.order.details.servlet.json',
        get_reOrder: '/.dow.reorder.details.servlet.json',
        get_deliveries: '/.dow.delivery.search.servlet.json',
        deliveryAddress_url: '/.dow.delivery.address.json',
        get_delivery: '/.dow.delivery.details.servlet.json',
        get_coaSearchList: '/.dow.coa.search.servlet.json',
        export_data: '/.dow.data.export.servlet.json',
        get_ship: '/.dow.ship.details.servlet.json',
        get_invoices: '/.dow.invoice.search.servlet.json',
        get_favorite_products: '/.dow.favorite.products.servlet.json',
        get_favorite_buying_options:
            '/.dow.favorite.buying.options.servlet.json',
        get_account_summary: '/.dow.account.summary.servlet.json',
        get_part_numbers: '/.dow.my.part.number.servlet.json',
        get_previous_purchases: '/.dow.previous.purchases.servlet.json',
        post_purchase_count: '/.dow.purchase.cart.count.json',
        download_export: '/dccstorefront/dcc/en/ordermanagement/downloadData',
        searchURL: 'search.html',
        applicator_search: '/.dow.applicator.search',
        COMPARE_PRODUCT: 'search/product-compare.html',
        buying_sample_options: '/.dow.buying.sample.options.servlet',
        init_drawer: '/.dow.init.drawer.servlet.json',
        check_availability: '/.dow.check.availability.servlet.json',
        drawer_lead_time: '/.dow.pdp.material.leadTime.json',
        save_cart_entry: '/.dow.save.cart.entry.servlet.json',
        update_drawer_flag: '/.dow.update.drawer.flag.json',
        contact_us: '/.dow.contactUs.servlet.json',
        get_cases: '/.dow.case.servlet.json',
        get_case_details: '/.dow.case.details.servlet.json',
        HSESSION_REFRESH: '/dccstorefront/dcc/en/_s/refresh',
        get_contract_details: '/.dow.contracts.details.servlet.json',
        create_order_from_contract: '/.dow.contract.order.create.servlet.json',
        quick_order: '/.dow.quick.order.servlet.json',
        get_myInfo: '/.dow.myAccount.myInfo.servlet.json',
        display_terms:'/.dow.myAccount.displayTerms.servlet.json',
        reset_terms:'/.dow.myAccount.resetTerms.servlet.json',
        get_myApplications: '/.dow.my.applications.servlet.json',
        get_myPreferences: '/.dow.myAccount.myPreferences.servlet.json',
        // CORP DOC Center
        get_asset_details: '/.dow.metadata.json',
        change_order: '/.dow.change.order.details.servlet.json',
        data_check: '/.dow.data.check.servlet.json',
        user_search_servlet: '/.dow.user.search.json',
        customer_administration_servlet: '/.dow.customeradministration.servlet.json',
        user_delivery_address_servlet: '/.dow.user.deliveryaddress.json',
        get_market_offers: '/.dow.market.offers.servlet.json',
        user_audit_trail_servlet: '/.dow.user.audit.trail.json',
    },
    mutations: {},
    getters: {},
    actions: {}
}
