import CoreComponent from '../../core/CoreComponent.vue';
import { NavMenu } from './nav-menu/index.js';
import ConfirmRegistration from '../site-login/ConfirmRegistration.vue';
import { SampleCartNotification } from '../../commerce/sampleCart/shared/cart-notification/index.js';
import { UserHelper } from '@/helpers/user-helper';
import Cart from '../header/cart/Cart.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import i18nHelper from '../../commerce/myAccount/helper/i18n-helper.js';
import { getCookiebyName } from '@/utils/cookies.js';
import { purifyLink } from '@/utils/url.js';
import Truck from '../../commerce/truck/Truck.vue';
const myAccountButton = document.querySelectorAll('.myAccount');
const signInRegister = document.querySelectorAll('.signInRegister');
export default {
  extends: CoreComponent,
  emits: [],
  data() {
    return {
      displayAccountModal: false,
      displayLanguageModal: false,
      displayTruckLoggedoutModal: false,
      displayTruckLoggedinModal: false,
      isReady: false,
      displayReauth: false,
      userCountry: '',
      userTown: '',
      formattedAddress: '',
      displayCartModal: false,
      isButtonHovered: false,
      isModalHovered: false,
      showForm: true,
      isMobileMenuOpen: false,
      displaying: false,
      contactUsLink: '',
      languages: {
        english: i18nHelper.getI18nMessage('english'),
        chinese: i18nHelper.getI18nMessage('chinese'),
        korean: i18nHelper.getI18nMessage('korean'),
        japanese: i18nHelper.getI18nMessage('japanese'),
        spanish: i18nHelper.getI18nMessage('spanish'),
        portuguese: i18nHelper.getI18nMessage('portuguese')
      }
    };
  },
  components: {
    NavMenu,
    SampleCartNotification,
    Cart,
    Truck
  },
  computed: {
    sampleCartLink: function () {
      return '/' + this.$store.state.browser.languageCode + '/sample-cart.html';
    },
    isLoggedIn() {
      return this.dccUserInfo?.data?.email;
    },
    isSearchChecker() {
      const search = 'search';
      const pageURL = this.$store.state.page.pageInfo.pageURL;
      return pageURL.includes(search);
    },
    totalCartCount() {
      let numberOfSampleCartItems = this.isLoggedIn ? this.dccUserInfo?.data?.numberOfSampleCartItems : this.anonSampleCartCount;
      var count = this.isLoggedIn ? parseInt(this.dccUserInfo?.data?.numberOfCartItems) + parseInt(numberOfSampleCartItems) : parseInt(numberOfSampleCartItems);
      return count;
    },
    isBuyingUser() {
      return this.dccUserInfo?.data?.baseRole?.includes('addtocart') ?? false;
    },
    hasSampleAdded() {
      return (this.isLoggedIn ? this.dccUserInfo?.data?.numberOfSampleCartItems : this.anonSampleCartCount) > 0;
    },
    hasCartContent() {
      return this.isBuyingUser || this.hasSampleAdded;
    },
    hasCartItems() {
      return this.totalCartCount > 0;
    },
    allcardWrappers() {
      return document.querySelectorAll('.cmp--header_cart-modal-dropdown');
    },
    navItemsWrapper() {
      return document.querySelectorAll('.my-account-nav-menu');
    },
    cartWrapper() {
      return document.querySelectorAll('.cmp--header_cart-modal_item-number');
    },
    country() {
      return this.$store.state.browser.country;
    },
    ...mapState('user', ['isAuthenticated', 'dccUserInfo', 'reauthorizationRequired', 'anonSampleCartCount']),
    ...mapState('browser', ['country'])
  },
  methods: {
    closeAccountModalByEsc() {
      document.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          if (this.displayAccountModal) {
            this.toggleAccountModal();
          }
        }
      });
    },
    closeLanguageModalByEsc() {
      document.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          if (this.displayLanguageModal) {
            this.toggleLanguageModal();
          }
        }
      });
    },
    toggleAccountModal() {
      const arrowElement = document.getElementsByClassName('myaccount-arrow-icon')[0];
      if (this.displayAccountModal) {
        arrowElement.style.transform = 'rotate(-90deg)';
      } else {
        arrowElement.style.transform = 'rotate(90deg)';
      }
      this.displayAccountModal = !this.displayAccountModal;
    },
    toggleUserCart() {
      const cartDiv = document.querySelectorAll('.cart-wrapper');
      if (cartDiv.length > 0) {
        cartDiv.forEach(cartDiv => {
          if (this.hasCartContent) {
            cartDiv.style.display = 'inline-block';
          } else {
            cartDiv.style.display = 'none';
          }
        });
      } else {
        console.log('Cart div not found and or null');
      }
    },
    toggleLanguageModal() {
      const arrowElement = document.getElementsByClassName('cmp--header_icon-arrow-sm')[0];
      if (this.displayLanguageModal) {
        arrowElement.style.transform = 'rotate(-90deg)';
      } else {
        arrowElement.style.transform = 'rotate(90deg)';
      }
      this.displayLanguageModal = !this.displayLanguageModal;
    },
    displayReauthModalMyAccount(value) {
      this.displayReauth = value;
      this.reloadRequired(false);
      this.reauthPostponed(value === true ? 'false' : 'true');
    },
    displayReauthModal(value) {
      this.displayReauth = value;
      this.reloadRequired(true);
    },
    toggleCartLInk() {
      const cartLink = document.querySelectorAll('.cmp--header_cart-modal');
      if (cartLink.length > 0) {
        cartLink.forEach(cartLink => {
          if (!this.isBuyingUser) {
            cartLink.setAttribute('href', 'javascript:;');
          } else {
            cartLink.setAttribute('href', '/dccstorefront/cart');
          }
        });
      } else {
        console.log('cartLink null');
      }
    },
    setupDropdown() {
      if (this.cmpEl !== null) {
        let cartButton = document.querySelectorAll('.cart-wrapper');
        cartButton.forEach(item => {
          item.addEventListener('click', e => {
            if (e.target.className === 'cmp--header_cart-modal-dropdown') {
              if (!this.hasCartContent) {
                e.preventDefault();
                window.location.href = this.cartUrl;
              }
            }
          });
          item.addEventListener('mouseenter', e => {
            this.isButtonHovered = true;
            this.isModalHovered = true;
          });
          item.addEventListener('mouseleave', e => {
            this.isModalHovered = false;
            setTimeout(() => {
              if (!this.isModalHovered) {
                this.isButtonHovered = false;
              }
            }, '300');
          });
        });
      }
    },
    openLoggedinModal() {},
    ...mapActions('user', ['logoutUser', 'loginUser']),
    ...mapMutations('user', ['reauthPostponed', 'reloadRequired']),
    toggleMicrositeMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    async switchLanguage(languageCode) {
      await this.$store.dispatch('browser/setLanguageCode', languageCode);
      if (window.location.href.includes('/dccstorefront/')) {
        window.location.reload();
      }
    },
    isValidItemLang(itemLang) {
      const validItemLangPattern = /^[a-z]{2}-[a-z]{2}$/;
      return validItemLangPattern.test(itemLang);
    },
    updateLinks() {
      var element = document.getElementById('mobileContactUsLink');
      if (element) {
        element.setAttribute('href', this.contactUsLink);
      }
    }
  },
  mounted() {
    this.isReady = true;
    this.setupDropdown();
    this.toggleUserCart();
    this.toggleCartLInk();
    this.closeAccountModalByEsc();
    this.closeLanguageModalByEsc();
    var sampleCartCount = 0;
    if (localStorage.getItem('sampleCart')) {
      var sampleJson = JSON.parse(localStorage.getItem('sampleCart'));
      sampleCartCount = sampleJson?.sampleCartCount || 0;
    }
    this.$store.commit('user/setAnonSampleCartCount', sampleCartCount || 0);
    this.$store.dispatch('user/updateDccUserInfo', true);
    this.contactUsLink = document.querySelector('.cmp--header_contact').getAttribute('href');
    this.updateLinks();
    const myAccount = document.querySelectorAll('.cmp--header_my-account');
    const logoutButton = document.querySelectorAll('.logoutButton');
    const closeButton = document.querySelector('.dc-modal__close');
    const loginModal = document.querySelectorAll('.dc-modal')[0];
    const usernameButton = document.querySelector('.dc-modal input[name=username]');
    const Languages = document.querySelector('.displayed-language-container');
    Languages.addEventListener('click', this.toggleLanguageModal);
    myAccount.forEach(item => {
      item.addEventListener('click', this.toggleAccountModal);
      item.addEventListener('keydown', e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          this.toggleAccountModal();
        }
      });
    });
    this.isAuthenticated === true ? myAccountButton.forEach(child => {
      child.classList.add('myAccount_active');
    }) : signInRegister?.forEach(child => {
      child.classList.add('signInRegister_active');
    });
    signInRegister?.forEach(child => {
      child.addEventListener('click', () => {
        document.body.style.overflow = 'hidden';
        loginModal.classList.add('active');
        if (usernameButton !== null) {
          usernameButton.focus();
        }
      });
      child.addEventListener('keydown', e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          document.body.style.overflow = 'hidden';
          loginModal.classList.add('active');
          if (usernameButton !== null) {
            usernameButton.focus();
          }
        }
      });
    });
    closeButton?.addEventListener('click', () => {
      loginModal.classList.remove('active');
      document.body.style.overflow = 'auto';
    });
    logoutButton.forEach(child => {
      child.addEventListener('click', event => {
        event.preventDefault();
        this.logoutUser();
      });
    });
    const mobileMenuButton = this.cmpEl.querySelector('.cmp--header_hamburger');
    mobileMenuButton?.addEventListener('click', () => {
      const openMenuIcon = this.cmpEl.querySelector('.cmp--header_hamburger--open');
      const closedMenuIcon = this.cmpEl.querySelector('.cmp--header_hamburger--closed');
      this.toggleMicrositeMobileMenu();
      if (this.isMobileMenuOpen) {
        openMenuIcon.classList.add('visible');
        openMenuIcon.classList.remove('hidden');
        closedMenuIcon.classList.add('hidden');
        closedMenuIcon.classList.remove('visible');
      } else {
        closedMenuIcon.classList.add('visible');
        closedMenuIcon.classList.remove('hidden');
        openMenuIcon.classList.add('hidden');
        openMenuIcon.classList.remove('visible');
      }
    });
  },
  watch: {
    country(newVal) {
      if (this.country != newVal) {
        this.country = newVal;
      }
    },
    reauthorizationRequired: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.displayReauthModal(true);
        }
      }
    },
    country(newVal) {
      this.$store.dispatch('browser/setTruckCountry', newVal);
    },
    isAuthenticated(newVal) {
      if (newVal) {
        myAccountButton.forEach(child => {
          child.classList.add('myAccount_active');
        });
        signInRegister?.forEach(child => {
          child.classList.remove('signInRegister_active');
        });
      } else {
        myAccountButton.forEach(child => {
          child.classList.remove('myAccount_active');
        });
        signInRegister?.forEach(child => {
          child.classList.add('signInRegister_active');
        });
      }
    },
    displayAccountModal(newVal) {
      if (newVal) {
        document.getElementsByClassName('cmp--header_my-account-modal')[0].style.display = 'flex';
        document.getElementsByClassName('cmp--header_my-account')[0].ariaExpanded = 'true';
        document.getElementsByClassName('cmp--header_language-modal')[0].style.display = 'none';
        if (this.displayLanguageModal) {
          this.toggleLanguageModal();
        }
        return;
      }
      document.getElementsByClassName('cmp--header_my-account-modal')[0].style.display = 'none';
      document.getElementsByClassName('cmp--header_my-account')[0].ariaExpanded = 'false';
    },
    displayLanguageModal(newVal) {
      if (newVal) {
        document.getElementsByClassName('cmp--header_language-modal')[0].style.display = 'flex';
        document.getElementsByClassName('cmp--header_language-modal')[0].style.flexDirection = 'column';
        document.getElementsByClassName('cmp--header_my-account-modal')[0].style.display = 'none';
        document.getElementsByClassName('cmp--header_my-account')[0].ariaExpanded = 'false';
        if (this.displayAccountModal) {
          this.toggleAccountModal();
        }
        return;
      }
      document.getElementsByClassName('cmp--header_language-modal')[0].style.display = 'none';
    },
    hasCartContent(newVal) {
      var elements = document.getElementsByClassName('cart-wrapper');
      if (elements.length > 0) {
        for (var i = 0; i < elements.length; i++) {
          if (newVal) {
            elements[i].classList.remove('hide-element');
          } else {
            elements[i].classList.add('hide-element');
          }
        }
        this.toggleUserCart();
      }
    },
    totalCartCount(newVal) {
      const elements = document.querySelectorAll('.cmp--header_cart-modal_item-number');
      elements.forEach(item => {
        item.textContent = newVal;
      });
      this.toggleUserCart();
    },
    isBuyingUser() {
      this.toggleCartLInk();
    }
  }
};